<template>
<div>
    <div class="screenHeight" v-bind:class="{changePosition: toggleClass}">
        <section class="checkpoints">

            <div class="container bottomSpace">
                <div class="backTo">
                    <router-link to="/home" class="noLine">
                        <svg focusable="false" data-prefix="fas" data-icon="long-arrow-alt-left" class="svg-inline--fa fa-long-arrow-alt-left fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                            <path fill="currentColor" d="M134.059 296H436c6.627 0 12-5.373 12-12v-56c0-6.627-5.373-12-12-12H134.059v-46.059c0-21.382-25.851-32.09-40.971-16.971L7.029 239.029c-9.373 9.373-9.373 24.569 0 33.941l86.059 86.059c15.119 15.119 40.971 4.411 40.971-16.971V296z"></path>
                        </svg>
                        <span>MY CHECKPOINTS</span>
                    </router-link>
                </div>
                <div class="pt-3" v-for="checkedRoad in allCheckedRoads" :key="checkedRoad.id">
                    <checkpoint-item :checkedRoad="checkedRoad" @removeFromList="removeFromList"></checkpoint-item>
                </div>
            </div>

            <router-link to="/myCheckpointsMap" class="seeMap">
                SEE ON MAPS
            </router-link>

            <mobile-navigation></mobile-navigation>
        </section>

    </div>
</div>
</template>

<script>
import MobileNavigation from '@/components/MobileNavigation';
import CheckpointItem from '@/components/CheckpointItem';
import Road from '@/models/Road';

export default {
    data() {
        return {
            checkpoints: [],
            allCheckedRoads: [],
            toggleClass: false,
        }
    },
    mounted() {
        this.renderCheckpoints();
    },
    methods: {
        async renderCheckpoints() {
            const checkpoints = this.$store.getters.getCheckpointList;
            for (let i = 0; i < checkpoints.length; i++) {
                this.allCheckedRoads[i] = await Road.find(checkpoints[i]);
            }

            if (this.checkpoints.length > 1) {
                this.toggleClass = true;
            } else {
                this.toggleClass = false;
            }
        },
        removeFromList() {
            this.checkpoints = [];
            this.allCheckedRoads = [];
            this.renderCheckpoints();
        }
    },
    components: {
        MobileNavigation,
        CheckpointItem
    }
}
</script>

<style lang="scss" scoped>
@import "../scss/variables.scss";

.screenHeight {
    height: 100%;
    min-height: 600px;

    .checkpoints {
        width: 100%;
        z-index: 100;
        background-color: white;

        @media screen and (min-width: 768px) {
            width: 88%;
            display: block;
            margin: auto;
            margin-left: 85px;
        }

        .backTo {
            padding-top: 30px;

            .noLine {
                text-decoration: none;
            }

            svg {
                height: 20px;
                color: $grey-text-color;
                margin-right: 20px;
            }

            span {
                color: $grey-text-color;
                font-weight: 500;
            }
        }

        .bottomSpace {
            padding-bottom: 100px;
        }

        .seeMap {
            position: fixed;
            right: 12px;
            bottom: 85px;
            background-color: white;
            padding: 8px 12px;
            text-decoration: none;
            color: #707070;
            box-shadow: 0 3px 6px #e8e8e8;
        }
    }
}
</style>

<template>
<div class="allCheckpoints pb-3">
    <div :id="'carousel'+checkedRoad.id" class="carousel slide" v-if="objImages.length > 0">
        <ol class="carousel-indicators">
            <li :data-bs-target="'#carousel'+checkedRoad.id" v-for="{id} in objImages" :key="id" :data-bs-slide-to="id" :class="{ 'active': id === 0 }"></li>
        </ol>
        <div class="carousel-inner">
            <div class="carousel-item" v-for="{id, url} in objImages" :key="id" :class="{ 'active': id === 0 }">
                <img :src="url" />
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row pt-3">
            <div class="col-9">
                <div class="row">
                    <div class="col-12">
                        <span>{{ checkedRoad.type.name }}</span>
                    </div>
                    <div class="col-12 pb-2">
                        <h5 class="pt-1"> {{ checkedRoad.name }} </h5>
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="map-marker-alt" class="navIcon svg-inline--fa fa-map-marker-alt fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                            <path fill="currentColor" d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"></path>
                        </svg>
                        <span>Mjesto, Država</span>
                    </div>
                </div>
            </div>
            <div class="col-3">
                <a href="#" type="button" data-bs-toggle="modal" :data-bs-target="'#modal'+checkedRoad.id">
                    <svg class="myList" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="25" height="22" viewBox="0 0 25 22">
                        <defs>
                            <clipPath id="clip-Like_-_on">
                                <rect width="25" height="22" />
                            </clipPath>
                        </defs>
                        <g id="Like_-_on" data-name="Like - on" clip-path="url(#clip-Like_-_on)">
                            <g id="Path_1264" data-name="Path 1264" transform="translate(-333.937 69.04)">
                                <path id="Path_1274" data-name="Path 1274" d="M346.756-48.305l-1.4-.007-10.121-10.06-.295-.709v-4.808l.447-.833,4.966-3.3,1.234.1,4.458,4.144,4.318-4.134,1.218-.129,5.333,3.3.474.85v4.808l-.3.717Z" fill="#1aa1ed" />
                            </g>
                        </g>
                    </svg>

                    <p class="textList pt-1">My List</p>
                </a>
            </div>
        </div>
        <div class="modal fade" :id="'modal'+checkedRoad.id" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Remove checkpoint</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <p>Are you sure you want to delete this checkpoint from My List?</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-outline-dark" data-bs-dismiss="modal">Cancel</button>
                        <button type="button" class="btn btn-danger" @click="removeFromList" data-bs-dismiss="modal">Delete</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div v-if="checkedRoad.description">
                  <span v-if="!content" class="description" v-html="checkedRoad.description.substring(0, 200) + '...'"></span>
                  <span v-if="content" class="description" v-html="checkedRoad.description"></span>
                  <button v-if="!content" @click="content = !content" class="btn btn-outline-secondary seeAll">See more</button>
                  <button v-if="content" @click="content = !content" class="btn btn-outline-secondary seeAll">See less</button>
                </div>
            </div>

        </div>

    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            objImages: [],
            idName: "",
            showModal: false,
            carousel: null,
            myCarousel: null,
            temp: "",
            content: false,
        }
    },
    props: {
        checkedRoad: {
            type: Object,
            required: true,
        },
    },
    mounted() {
        if (this.checkedRoad.images) {
            for (let i = 0; i < this.checkedRoad.images.length; i++) {
                this.objImages[i] = {
                    id: i,
                    url: this.checkedRoad.images[i].url
                }
            }
        }
    },
    methods: {
        modalWindow() {
            this.showModal = true;
        },
        removeFromList() {
            this.$store.dispatch('removeCheckpointList', this.checkedRoad.id);
            this.$emit('removeFromList');
        },
    },
}
</script>

<style lang="scss" scoped>
@import "../scss/variables.scss";
.allCheckpoints {
    box-shadow: 1px 1px 3px #e8e8e8;

    @media screen and (min-width: 768px) {
        max-height: 100%;
        height: 100%;
    }

    .carousel {
        width: 100%;
        height: 160px;
        position: relative;
        //padding: 0;

        @media screen and (min-width: 768px) {
            height: 280px;
        }

        .carousel-indicators {
            li {
                width: 8px;
                height: 8px;
                margin-bottom: -16px;
            }
        }

        .carousel-item {
            img {
                width: 100%;
                max-height: 160px;
                min-height: 160px;
                object-fit: cover;

                @media screen and (min-width: 768px) {
                    max-height: 280px;
                    height: 280px;
                }
            }
        }

    }

    span {
        margin-right: 14px;
        color: #bebebe;
        font-size: 14px;
    }

    .myList {
        width: 30px;
        height: auto;
        color: #00A1ED;
        display: block;
        margin: auto;
    }

    .textList {
        display: block;
        margin: auto;
        text-align: center;
        color: #707070;
    }

    .navIcon {
        width: 12px;
        height: auto;
        fill: #bebebe;
        color: #bebebe;
        margin-right: 10px;
    }

    a {
        text-decoration: none;
        color: #707070;

        +p {
            display: block;
            margin: auto;
            text-align: center;
            color: #707070;
        }
    }

    h5 {
        color: #707070;
        font-weight: 600;
        margin-bottom: 2px;
    }

    .description{
      color: #707070;
    }

  .seeAll{
    padding: 2px 14px;
    font-size: 16px;
    width: 100%;
    margin-top: 9px;

    &:hover, &:active{
      color: $secondary;
      background-color: white;
      border: 2px solid $secondary;
    }
  }

    .inlineButtons {
        width: 100%;

        .infoButton {
            background-color: #EBEBEB;
            color: #707070;
            padding: 10px 12px;
            text-decoration: none;
            margin-right: 12px;

            svg {
                fill: #707070;
                width: 16px;
                height: auto;
                margin-right: 4px;
            }
        }

        @media screen and (max-width: 373px) {
            .infoButton {
                padding: 10px 10px;
                margin-right: 8px;

                svg {
                    margin-right: 2px;
                }
            }
        }

        @media screen and (max-width: 359px) {
            .infoButton {
                font-size: 14px;

                svg {
                    width: 14px;
                }
            }
        }
    }

    .modal {
        font-family: "Roboto", sans-serif;

        .modal-footer {
            button {
                padding: 6px 10px;
            }
        }
    }
}
</style>
